.notification::-webkit-scrollbar {
  width: 4px;
  height: 60px;
}
.notification::-webkit-scrollbar-thumb {
  background: #F28585;
  border-radius: 4px;
}
.headerIcon {
  position: relative;
  .ant-avatar {
    float: right;
    margin-left: 10px;
    margin-top: 15px;
    color: #4f5d75;
    background: transparent;
    &:hover {
      background: #fff;
      box-shadow: 0px 0px 10px 4px rgba(150, 190, 238, 0.3);
      cursor: pointer;
    }
  }
  .last {
    margin-right: 30px;
  }
}

.profileDropdown {
  .pad15 {
    display: flex;
  }
  .info {
    float: left;
    display: inline;
    padding-left: 15px;
    p {
      margin-bottom: 0;
    }
  }
}

.notifications {
  width: 100%;
  .pad10 {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-right: 6px;
    padding-bottom: 0px;
  }
  .notif-list {
    max-height: 200px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 3px;
      height: 60px;
    }
    &::-webkit-scrollbar-thumb {
      background: #F28585;
      border-radius: 4px;
    }

    .notification {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px;
      gap: 4px;
      border-bottom: 1px;
      // border-bottom: 1px solid #f5f5f5;
      .notif-btn {
        padding: 4px;
        &:hover {
          background-color: #FCF5EB;
        }
        span {
          width: 150px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }
  }
}
.del-notif:hover {
  background-color: #ffeeee;
}
